import React from 'react';
import './LogoFooter.scss';

export const Logo = props => {
  return (
    <MyImg />
  );
}

const MyImg = () => {
  return (
    <img alt="Footer Logo for the Republic of Fremantle" src={'/logos/rof_white.svg'} />
  )
}