import React from 'react';
import { Link } from 'gatsby';
import { decodeEntities } from '../utils/htmlParse';
import { edgeTest } from '../utils/helpers'


export default class GatsbyLink extends React.Component {

  scrollIntoView = (event, containerId) => {
    if (typeof window !== 'undefined') {
      event.preventDefault();
      containerId = containerId.replace('#','');
      const results = document.getElementById(containerId) ? document.getElementById(containerId) : null;
      results && results.scrollIntoView({ block: 'start',  behavior: 'smooth' });
    }
  }

  render() {
    const {to, activeClassName = "active", children, data, decode = false, ...other } = this.props

    if ( to === '#' ) {
      return <span className='no-link'>{decode ? decodeEntities(children) : children}</span>
    }

    if ( to && to.includes('#') ) {
      return <span onClick={event => this.scrollIntoView(event, to)} {...other}>{decode ? decodeEntities(children) : children}</span>
    }

    if (to && to.startsWith('/') && edgeTest()) {
      return <Link activeClassName={activeClassName} {...other} to={to}>{decode ? decodeEntities(children) : children}</Link>
    }

    return (
      <a href={to} {...other}>{decode ? decodeEntities(children) : children}</a>
    )
  }
}