import React, { Component } from 'react';
import { decodeEntities } from '../utils/htmlParse';

export default class RenderContent extends Component {
  render() {
    const { content = '', excerpt = false, data, className = "entry-content"} = this.props;
    //const { siteUrl } = data.site.siteMetadata;
    //const { wordpressUrl } = data.wordpressWpSettings;
    const { render_content = excerpt ? content.split('<!--more-->')[0] : content } = content;
    var new_content = render_content ? render_content.replace('<iframe', '<div class="outer-video"><div class="video"><iframe') : '';
    var new_content = new_content ? new_content.replace('</iframe>', '</iframe></div></div>') : '';
    //var new_content = new_content ? new_content.replace(new RegExp(wordpressUrl,'g'), '').replace(new RegExp(siteUrl,'g'), '') : new_content;

    return (
      <div
          className={className}
          dangerouslySetInnerHTML={{__html: decodeEntities(new_content)}}
      />
    );
  }
}
