// https://www.npmjs.com/package/react-mailchimp-form
import React, { Component } from 'react'
import Helmet from 'react-helmet';

import './SubscribeForm.scss'

// const url = `https://api.createsend.com/api/v3.2/subscribers/5B5E7037DA78A748374AD499497E309E7B43D4F7380399F697C6EF4EAC3CE190E4A8D0B2D37CFDE0D93A31244A281B28E2AD8E4430C5477F52BE2DD07C8AD218`

export class SubscribeForm extends Component {
  render(props = {}) {
    const { className } = this.props;
    return (
      <>
      <Helmet>
        <script
        async defer
          type="text/javascript"
          src="https://js.createsend1.com/javascript/copypastesubscribeformlogic.js">
        </script>
        </Helmet>
      <form
        className={'js-cm-form'}
        id="subForm"
        action={`https://www.createsend.com/t/subscribeerror?description=`}
        method={'post'}
        data-id="5B5E7037DA78A748374AD499497E309E7B43D4F7380399F697C6EF4EAC3CE190E4A8D0B2D37CFDE0D93A31244A281B28E2AD8E4430C5477F52BE2DD07C8AD218"
      >
        <div className="input-wrapper">
          <input 
            aria-label="Full Name"
            name="cm-name" 
            id="fieldName" 
            required={true} 
            type='text' 
            placeholder='Full Name*'>
          </input>
          <input 
            aria-label="Email Address"
            className="js-cm-email-input"
            id="fieldEmail"
            maxLength="200"
            name="cm-ukjluth-ukjluth"
            required={true} 
            type='email' 
            placeholder='Email Address*'>
          </input>
        </div>
        <div className="button-text-wrapper">
          <p>* Required Fields
            </p>
          <button type="submit" aria-label="Subscribe to email list" className="js-cm-email-input email-form__button">SIGN UP</button>
        </div>
      </form>
    </>
    )
  }
}
