import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { Logo } from '../../theme/LogoFooter';
import GatsbyLink from './GatsbyLink';
import RenderContent from './RenderContent';
import { SocialLinks } from './SocialLinks'
import { FaEnvelope, FaPhone, FaMapPin } from 'react-icons/fa';

import './Footer.scss';
import { decodeEntities } from '../utils/htmlParse';
import { SubscribeForm } from './SubscribeForm';

const isClient = typeof window !== 'undefined';

class Footer extends Component {
  sendEmail(event){
    event.preventDefault()
    const { data } = this.props
    const { email } = data.siteSettings.options.locations[0]
      window.location = `mailto:${decodeEntities(email)}`;
  }
  render() {
    const { data, location = {} } = this.props;

    const {
      legalNavigation,
      footerNavigation,
      siteSettings,
      metaData,
      wordpressSettings
    } = data;

    const { url: baseUrl } = metaData;
    const { items: legalNav } = legalNavigation;
    const { items: footerNav } = footerNavigation
    const { footerTagline, navigationInFooter, contactDetailsInFooter } = siteSettings.options
    const {
      email,
      phone,
      addressDisplay,
      locationName,
      addressCity,
      addressStreet,
      addressRegion,
      addressPostCode,
    } = siteSettings.options.locations[0];
    
    const currentYear = (new Date()).getFullYear();

    return (
      <footer>
        <div className="footer-main">
          <div className="inner">
            <div className="wrap">

              <Logos className="logos" tagLine={footerTagline && <RenderContent className="tagline" content={footerTagline}/>} />
              {contactDetailsInFooter &&
                <div className="contact-details">
                  {locationName && <span className="location-name" dangerouslySetInnerHTML={{__html: locationName}} />}<br />
                  {addressStreet && <span dangerouslySetInnerHTML={{__html: addressStreet}} />}<br />
                  {addressCity && <span dangerouslySetInnerHTML={{__html: addressCity}} />}
                  {addressRegion && addressPostCode && <span dangerouslySetInnerHTML={{__html: `, ${addressRegion} ${addressPostCode}`}}/>}
                  {/* {addressDisplay && <div><FaMapPin />{addressDisplay}</div>} */}
                  {phone && <div><a
                    href={`tel:${phone.replace(/[^A-Z0-9]/ig, "")}`}
                    className='control-call'
                    aria-label="Call Now"
                  >{phone}</a></div>}
                  {email && 
                    <div>
                      <a 
                        href={`mailto:${email}?subject=I want to join the republic of Fremantle!`} 
                        onClick={(event) => this.sendEmail(event)}>
                          <span dangerouslySetInnerHTML={{__html: email}} />
                      </a>
                    </div>}
                    {siteSettings.options.socialLinksFooter && <SocialLinks/>}
                </div>
              }


              {navigationInFooter &&
                <ul className="footer-nav">
                {footerNav  && footerNav.map((item, index) => (
                  <li key={index}><GatsbyLink to={item.url} aria-label={item.title} decode={true}>{item.title}</GatsbyLink></li>
                ))
                }
                </ul>
              }
              <div className="email-form__wrapper">
                <div className="copy-wrapper">
                  <p><strong>Thirsty for more?</strong></p>
                  <p className="info-text">Enter your details below and we will keep you up to date with news and product launches</p>
                </div>
                <div className='form-wrapper'>
                  <SubscribeForm className="form__subscribe" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="disclaimers">
           <div className="wrap">
               <div className="copyright">
                 <span>©{currentYear} {wordpressSettings.nodes[0].title}</span>
               </div>

               {legalNav &&
                 <div className="navigation">
                   <ul>
                     {legalNav.map((item, index) => (
                       <li key={index}><GatsbyLink to={item.url} decode={true}>{item.title}</GatsbyLink></li>
                     ))}
                   </ul>
                 </div>
               }
           </div>

         </div>
      </footer>
    );
  }
}

/* Logos compononent
 * @params className, tagLine
 */
const Logos = (props) => {
  return (
    <div className={props.className}>
      <Logo />
    </div>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        legalNavigation: wordpressWpApiMenusMenusItems(slug: { eq: "legal-navigation" }) {
          items {
            title
            url
            classes
            slug: object_slug
          }
        },
        footerNavigation: wordpressWpApiMenusMenusItems(slug: { eq: "footer-navigation" }) {
          items {
            title
            url
            classes
            slug: object_slug
            children: wordpress_children {
              title
              url
              classes
              slug: object_slug
            }
          }
        },
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            locations {
              locationName
              addressCity
              addressDisplay
              addressStreet
              addressRegion
              addressPostCode
              email
              phone
            }
            footerTagline
            socialLinksFooter
            navigationInFooter
            contactDetailsInFooter
            mailchimpApiKey
          }
        },
        metaData: wordpressSiteMetadata {
          url
        }
        wordpressSettings: allWordpressWpSettings {
          nodes {
            title
          }
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
)
