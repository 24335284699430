import React from 'react';
import GatsbyLink from '../src/components/GatsbyLink';
import './Logo.scss';

const MyImg = () => {
  return (
    <img alt="Republic of Fremantle Logo" src={'/logos/RoFPrimaryLogoRGB.svg'} />
  )
}

export const Logo = () => {
  return (
    <GatsbyLink to="/" className="logo">
      <MyImg />
    </GatsbyLink>
  );
}
